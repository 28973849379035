import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import HubspotForm from "react-hubspot-form"
import { Layout } from "../../components/Layout"
import { Carousel } from "react-bootstrap"
import "./styles.css"
export default function GetADemo() {
  return (
    <Layout
      title="Experience The Power Of Generative AI For Free With IrisAgent"
      keywords="IrisAgent, demo, incidents, tickets"
      description="Try out IrisGPT bot trained on your data for free to experience the power of Generative AI to automate tagging, responses and workflows with no-code integration"
    >
    <Helmet>
        <link rel="canonical" href="https://irisagent.com/try-for-free/" />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= -->
    <!-- <section className="breadcrumbs">
      <div className="container">

        <div className="d-flex justify-content-between align-items-center">
          <h2>Book Demo</h2>
          <ol>
            <li><a href="index.html">Home</a></li>
            <li>Demo</li>
          </ol>
        </div>

      </div>
    </section> --><!-- End Breadcrumbs --> */}
      <Helmet>
        <script
          charSet="utf-8"
          src="https://js.hsforms.net/forms/shell.js"
        ></script>
      </Helmet>

      <section className="inner-page" style={{ margin: "2% 0" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div>
                <br />
                <br />
                <br />
                <h1 style={{ fontWeight: 500 }}>Experience the power of Generative AI for Free</h1>
                <br />
                <p style={{ fontSize: 24 }}>

                  Leverage AI to automate tagging, responses, and workflows seamlessly with no-code integration. Try out a personalized IrisGPT bot trained on your data for free to experience the power of Generative AI.
                </p>
                <Carousel controls={false}>
                  <Carousel.Item style={{ marginBottom: "8%" }}>
                    <div className="testimonial-item">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                          }}
                        >
                          <img
                            // className="testimonial_logo"
                            src="/img/free-access-1.svg"
                            alt="First slide"
                          />
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>

                  <Carousel.Item style={{ marginBottom: "8%" }}>
                    <div className="testimonial-item">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                          }}
                        >
                          <img src="/img/free-access-2.svg" alt="First slide" />
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item style={{ marginBottom: "8%" }}>
                    <div className="testimonial-item">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                          }}
                        >
                          <img src="/img/free-access-3.svg" alt="First slide" />
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item style={{ marginBottom: "8%" }}>
                    <div className="testimonial-item">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                          }}
                        >
                          <img src="/img/free-access-4.svg" alt="First slide" />
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>

            <div className="col-md-6">
              <div>
                <br />
                <br />
                <br />
                <HubspotForm
                  portalId="7494256"
                  formId="116c2d21-8285-4434-b821-08126a7ea175"
                  loading={<div>Loading...</div>}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
